<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4">
      <va-card-title :style="isMobile ? 'font-size: 1.25rem;' : 'font-size: 1.5rem;'">
        Grouped Files&nbsp;
        <span v-if="session">- {{ session.label }}</span>&nbsp;
        <va-icon :name="'fa4-edit'" class="link" style="padding-left: 1rem;" @click="editModal" />
        <va-badge
          overlap
          :text="attachments"
        >
          <va-icon :name="'fa4-paperclip'" class="link" style="padding-left: 1rem;" @click="showAttachments" />
        </va-badge>
        <va-popover
          class="mr-2 mb-2"
          message="Show all files"
          color="primary"
        >
          <router-link :key="uid" :to="{ name: 'jointfiles', params: { id }}">
            <va-icon :name="'fa4-eye'" style="padding-left: 1rem;" />
          </router-link>
        </va-popover>
      </va-card-title>
      <va-card-content v-if="session">
        <va-data-table
          :items="files"
          :columns="columns"
          :selectable="false"
        >
          <template #cell(uid)="{ source: uid }">
            <router-link :key="uid" :to="{ name: 'jointfiles', params: { id }, query: { mode: uid }}">
              <va-icon :name="'fa4-eye'"/>
            </router-link>
          </template>
        </va-data-table>
      </va-card-content>
    </va-card>

    <va-modal
      v-model="editModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Edit a Session</h2>
      </template>
      <slot>
        <va-input
          class="mb-3"
          v-model="session.label"
          type="text"
          label="Label"
          style="margin-top: 1rem;"
        />
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="editModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="primary" @click="editModalConfirm">Save</va-button>
      </template>
    </va-modal>

  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { isMobile } from 'mobile-device-detect'

export default {
  mounted: async function () {
    this.loader = this.$loading.show()
    try {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      // Load session
      const session = await axios.get(`${process.env.VUE_APP_API_URL}/api/jointsessions/${this.id}`, { headers })
      this.session = session.data.jointsession

      // Load files
      const files = await axios.get(`${process.env.VUE_APP_API_URL}/api/jointsessions/${this.id}/files-summary`, { headers })
      this.files = []

      if (files.data.files.video_files > 0) {
        this.files.push({ uid: 'Video Mode', name: 'Video Mode', count: files.data.files.video_files })
      }
      if (files.data.files.photo_files > 0) {
        this.files.push({ uid: 'Photo Mode', name: 'Photo Mode', count: files.data.files.photo_files })
      }
      if (files.data.files.heartbeat_files > 0) {
        this.files.push({ uid: 'Telemetry Mode', name: 'Telemetry Mode', count: files.data.files.heartbeat_files })
      }
      if (files.data.files.threed_files > 0) {
        this.files.push({ uid: 'LIDAR Mode', name: 'LIDAR Mode', count: files.data.files.threed_files })
      }

      // Load attachments
      const attachments = await axios.get(`${process.env.VUE_APP_API_URL}/api/jointsessions/${this.id}/attachments`, { headers })
      this.attachments = attachments.data.attachments.length
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: `/pages/jointsessions/${this.id}/files` } })
    }
    this.loader.hide()
  },
  data () {
    return {
      isMobile,
      loader: null,
      token: this.$store.state.token,
      id: this.$route.params.id,
      session: null,
      files:[],
      attachments: [],
      editModalShow: false,
      columns: [
        {
          key: 'name',
          label: 'Mode',
          headerTitle: 'Mode',
          sortable: true
        },
        {
          key: 'count',
          label: 'No of files',
          headerTitle: 'No of files',
          sortable: true
        },
        {
          key: 'uid',
          label: 'Action',
          headerTitle: 'Action'
        }
      ]
    }
  },
  methods: {
    editModal () {
      this.editModalShow = true
    },
    editModalConfirm: async function () {
      this.loader = this.$loading.show()
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        const payload = {
          label: this.session.label
        }
        // Edit a session
        await axios.put(`${process.env.VUE_APP_API_URL}/api/jointsessions/${this.session.uid}`, payload, { headers })
        // Show success message
        this.$vaToast.init({
          message: 'Session edited successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        // Load session again
        const session = await axios.get(`${process.env.VUE_APP_API_URL}/api/jointsessions/${this.session.uid}`, { headers })
        this.session = session.data.jointsession

        this.editModalShow = false
      } catch (error) {
        this.editModalShow = false
        this.$router.push({ name: 'login', query: { redirect: `/pages/jointsessions/${this.id}/files` } })
      }
      this.loader.hide()
    },
    showAttachments() {
      this.$router.push({ name: 'jointattachments', params: { id: this.id } })
    }
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }

  .table-example--pagination {
    text-align: right;
    text-align: -webkit-right;
    padding-top: 1rem;
  }

  .link {
     cursor: pointer;
     color: var(--va-primary);
  }

  .link:hover {
     text-decoration: none;
  }

  .va-data-table {
    --va-data-table-thead-color: var(--va-primary);
  }

  .va-pagination__input {
    background-color: var(--va-white);
  }

  .va-alert {
    background-color: var(--va-white) !important;;
  }
</style>
